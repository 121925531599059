import { url } from '@/config'
export default {
  menuWidth: 180,
  translate: false,
  searchLabelWidth: 100,
  labelWidth: 120,
  selection: true,
  tip: false,
  index: true,
  align: 'center',
  headerAlign: 'center',
  border: true,
  stripe: true,
  addBtn:false,
  editBtn:false,
  viewBtn:true,
  delBtn:false,
  translate: false,
  calcHeight: 60,
  page:false,
  column: [{
    label: 'identifier',
    prop: 'identifier'
  },{
    label: '描述',
    prop: 'desc'
  }, {
    label: 'mappedBean',
    prop: 'mappedBean'
  }]
}