import { url } from '@/config'
export default {
  menuWidth: 180,
  translate: false,
  searchLabelWidth: 100,
  labelWidth: 120,
  selection: true,
  tip: false,
  index: true,
  align: 'center',
  headerAlign: 'center',
  border: true,
  stripe: true,
  translate: false,
  calcHeight: 60,
  column: [{
    label: '名称',
    prop: 'name',
    search: true
  }, {
    label: '描述',
    prop: 'desc'
  },{
    label: '命令动作',
    prop: 'command',
    span:24,
    type:'select',
    formslot: true,
    search: true,
    searchslot:true,
    hide:true,
    showColumn:false
  },
  {
    label: '命令动作',
    prop: 'commandDesc',
    display:false
  },
  {
    label: '模版绑定用户',
    prop: 'sendToUserIds',
    span:24,
    formslot: true,
    display:false,
    hide:true,
    showColumn:false
  }, {
    label: 'content',
    span:24,
    prop: 'content',
    overHidden:true,
    type: 'textarea'
  }]
}