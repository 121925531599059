import request from '@/utils/request'
import { url } from '@/config'
let common = url + "/casecommon/";
import Date from '@/utils/datetime.js'
export const caseList = (data) => {
  return request.get(common + "caseList", {
    params: data
  });
}

export const delCaseById = (id) => {
  return request.delete(common + "case/" + id);
}

export const caseAddOrUp = (data) => {
  return request.post(common + "caseAddOrUp", data);
}

export const moduleList = (data) => {
  return request.get(common + "moduleList", {
    params: data
  });
}

export const sumbitNewStatus = (data) => {
  data = {
    ...data,
    endTime: new Date().strftime(),
    startTime: new Date().strftime(),
  }
  return request.post(common + "caseSubmit", data);
}

export const orgList = (data) => {
  return request.get(url + "/org-manage/list", {
    params: data
  });
}

export const delModule = (id) => {
  return request.delete(common + "module/" + id);
}

export const moduleAddOrUp = (data) => {
  return request.post(common + "moduleAddOrUp", data);
}

export const methodList = (data) => {
  return request.get(common + "methodList", {
    params: data
  });
}

export const delMethod = (id) => {
  return request.delete(common + "method/" + id);
}

export const methodAddOrUp = (data) => {
  return request.post(common + "methodAddOrUp", data);
}