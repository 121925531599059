
import request from '@/utils/request'
import { url } from '@/config'
export const list = (data) => {
  return request({
    url: url + '/authority/role/list',
    method: 'post',
    params: data
  })
}
export const del = (ids) => request({
  url: url + '/authority/role/delete/' + ids,
  method: 'post'
})
export const add = (data) => request({
  url: url + '/authority/role/upsert',
  method: 'post',
  data: data
})
export const detail = (ids) => request({
  url: url + '/authority/role/detail/' + ids,
  method: 'get',
})
export const update = (id, data) => add(data);

export const bind = (id, data) => request({
  url: url + '/authority/role/bind/' + id,
  method: 'post',
  data: data
})