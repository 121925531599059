
import request from '@/utils/request'
import { url } from '@/config'
import Date from '@/utils/datetime.js'
export const list = (data,searchData) => {
  return request({
    url: url + `/job-task-command/page-jobs`,
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    params:data,
    data:JSON.stringify(searchData)
  })
}
export const del = (id) => request.delete(url + '/job-task-command/delete-job/'+id)



export const update = (data) => request({
  url: url + '/job-task-command/upsert-job',
  method: 'post',
  data
})
export const add = (data) => update(data)

export const getOrglist = () => {
  return request({
    url: url + `/job-task-command/list-organizations`,
    method: 'get'
  })
}



