export default {
  menuWidth: 180,
  translate: false,
  labelWidth: 130,
  selection: true,
  tip: false,
  index: true,
  height: 'auto',
  align: 'center',
  headerAlign: 'center',
  border: true,
  stripe: true,
  translate: false,
  calcHeight: 60,
  column: [{
    label: '镇街',
    prop: 'street',
    rules: [{
      required: true,
      message: "请输入镇街",
      trigger: "blur"
    }]
  }, {
    label: '房屋名称',
    prop: 'houseName',
    rules: [{
      required: true,
      message: "请输入房屋名称",
      trigger: "blur"
    }]
  }, {
    label: '安全责任人/产权人（使用人）',
    prop: 'rightPerson',
    rules: [{
      required: true,
      message: "请输入安全责任人/产权人（使用人）",
      trigger: "blur"
    }]
  }, {
    label: '身份证号码（机构代码）',
    prop: 'idCard',
    rules: [{
      required: true,
      message: "请输入身份证号码（机构代码）",
      trigger: "blur"
    }]
  }, {
    label: '责任人联系电话',
    prop: 'responsiblePhone',
    rules: [{
      required: true,
      message: "请输入责任人联系电话",
      trigger: "blur"
    }]
  }, {
    label: '房屋座落地址',
    prop: 'houseAddress',
    overHidden: true,
    span: 24,
    rules: [{
      required: true,
      message: "请输入房屋座落地址",
      trigger: "blur"
    }]
  }, {
    label: '自建房',
    prop: 'selfBuild',
    rules: [{
      required: true,
      message: "请输入自建房",
      trigger: "blur"
    }]
  }, {
    label: '非自建房',
    prop: 'notSelfBuild',
    rules: [{
      required: true,
      message: "请输入非自建房",
      trigger: "blur"
    }]
  }, {
    label: '建造年代',
    prop: 'buildYear',
    rules: [{
      required: true,
      message: "请输入建造年代",
      trigger: "blur"
    }]
  }, {
    label: '结构类型',
    prop: 'instructType',
    rules: [{
      required: true,
      message: "请输入结构类型",
      trigger: "blur"
    }]
  }, {
    label: '建筑面积',
    prop: 'acreage',
    append: '㎡',
    rules: [{
      required: true,
      message: "请输入建筑面积",
      trigger: "blur"
    }]
  }, {
    label: '层数',
    prop: 'layer',
    append: '层',
    rules: [{
      required: true,
      message: "请输入层数",
      trigger: "blur"
    }]
  }, {
    label: '建造方式',
    prop: 'buildMethod',
    rules: [{
      required: true,
      message: "请输入建造方式",
      trigger: "blur"
    }]
  }, {
    label: '土地性质',
    prop: 'landType',
    rules: [{
      required: true,
      message: "请输入土地性质",
      trigger: "blur"
    }]
  }, {
    label: '现在用途',
    prop: 'nowUsage',
    rules: [{
      required: true,
      message: "请输入现在用途",
      trigger: "blur"
    }]
  }, {
    label: '是否改造',
    prop: 'isChange',
    rules: [{
      required: true,
      message: "请输入是否改造",
      trigger: "blur"
    }]
  }, {
    label: '是否用作经营',
    prop: 'business',
    rules: [{
      required: true,
      message: "请输入是否用作经营",
      trigger: "blur"
    }]
  }, {
    label: '是否保护性建筑',
    prop: 'isProtect',
    rules: [{
      required: true,
      message: "请输入是否保护性建筑",
      trigger: "blur"
    }]
  }, {
    label: 'B级',
    prop: 'levelA',
    rules: [{
      required: true,
      message: "请输入A级",
      trigger: "blur"
    }]
  }, {
    label: 'B级',
    prop: 'levelB',
    rules: [{
      required: true,
      message: "请输入是B级",
      trigger: "blur"
    }]
  }, {
    label: 'C级',
    prop: 'level3',
    rules: [{
      required: true,
      message: "请输入C级",
      trigger: "blur"
    }]
  }, {
    label: 'D级',
    prop: 'levelD',
    rules: [{
      required: true,
      message: "请输入D级",
      trigger: "blur"
    }]
  }, {
    label: '拆除',
    prop: 'destruct',
    rules: [{
      required: true,
      message: "请输入拆除",
      trigger: "blur"
    }]
  }, {
    label: '重建',
    prop: 'rebuild',
    rules: [{
      required: true,
      message: "请输入拆除",
      trigger: "blur"
    }]
  }, {
    label: '修缮加固',
    prop: 'strengthening',
    rules: [{
      required: true,
      message: "请输入修缮加固",
      trigger: "blur"
    }]
  }, {
    label: '停用、人员撤离',
    prop: 'stopLeave',
    rules: [{
      required: true,
      message: "请输入停用、人员撤离",
      trigger: "blur"
    }]
  }, {
    label: '设立警戒线、设置围护措施',
    prop: 'waningNoteSetting',
    rules: [{
      required: true,
      message: "请输入设立警戒线、设置围护措施",
      trigger: "blur"
    }]
  }, {
    label: '未治理',
    prop: 'noGovern',
    rules: [{
      required: true,
      message: "请输入未治理",
      trigger: "blur"
    }]
  }, {
    label: '是否销号',
    prop: 'cancel',
    rules: [{
      required: true,
      message: "请输入未治理",
      trigger: "blur"
    }]
  }, {
    label: '是否疑似危房',
    prop: 'isRisk',
    rules: [{
      required: true,
      message: "请输入是否疑似危房",
      trigger: "blur"
    }]
  }, {
    label: '具体隐患点',
    prop: 'dangrouseDetail',
    type: 'textarea',
    span: 24,
    rules: [{
      required: true,
      message: "请输入具体隐患点",
      trigger: "blur"
    }]
  }, {
    label: '备注',
    prop: 'remark',
    type: 'textarea',
    overHidden: true,
    span: 24,
    rules: [{
      required: true,
      message: "请输入备注",
      trigger: "blur"
    }]
  }]
}