export default {
  menuWidth: 180,
  translate: false,
  labelWidth: 130,
  selection: true,
  tip: false,
  index: true,
  height: 'auto',
  align: 'center',
  headerAlign: 'center',
  border: true,
  stripe: true,
  translate: false,
  calcHeight: 60,
  column: [{
    label: '名称',
    prop: 'name',
    rules: [{
      required: true,
      message: "请输入名称",
      trigger: "blur"
    }]
  }, {
    label: 'code',
    prop: 'code',
    width: 100,
    overHidden: true,
    rules: [{
      required: true,
      message: "请输入code",
      trigger: "blur"
    }]
  }, {
    label: 'appId',
    prop: 'appId',
    width: 100,
    overHidden: true,
    rules: [{
      required: true,
      message: "请输入appId",
      trigger: "blur"
    }]
  }, {
    label: 'tagId',
    prop: 'tagId',
    rules: [{
      required: true,
      message: "请输入tagId",
      trigger: "blur"
    }]
  }, {
    label: '启用大屏',
    prop: 'dataScreen',
    type: 'radio',
    dicData: [{
      label: '是',
      value: 1
    }, {
      label: '否',
      value: 0
    }],
    rules: [{
      required: true,
      message: "请选择是否启用大屏",
      trigger: "blur"
    }]
  }, {
    label: '演示',
    prop: 'isDemo',
    type: 'radio',
    dicData: [{
      label: '是',
      value: 1
    }, {
      label: '否',
      value: 0
    }],
    rules: [{
      required: true,
      message: "请选择是否演示",
      trigger: "blur"
    }]
  }, {
    label: '监视大屏',
    prop: 'monitor',
    type: 'radio',
    dicData: [{
      label: '是',
      value: 1
    }, {
      label: '否',
      value: 0
    }],
    rules: [{
      required: true,
      message: "请选择是否监视大屏",
      trigger: "blur"
    }]
  }, {
    label: '公众号地址',
    width: 100,
    overHidden: true,
    prop: 'url'
  }, {
    label: '文件地址',
    width: 100,
    overHidden: true,
    prop: 'filePath'
  }, {
    width: 160,
    label: '创建时间',
    prop: 'updateTime',
    disabled: true,
    addDisplay: false
  }, {
    width: 160,
    label: '更新时间',
    prop: 'updateTime',
    disabled: true,
    addDisplay: false
  }]
}