import {
  mapGetters
} from "vuex";
export default (app, option = {}) => {
  console.log(option, require(`@/table${option.name}`).default)
  let mixins = {
    data () {
      return {
        selection: [],
        option: require(`@/table${option.name}`).default,
        data: [],
        form: {},
        search: {},
        params: {},
        api: require(`@/api${option.name}`),
        loading: false,
        page: {
          pageSizes: [10, 20, 30, 40, 50, 100],
          pageSize: 10
        },
      }
    },
    computed: {
      ...mapGetters(['userInfo', 'permissions', 'isDebug', 'roles']),
      ids () {
        let ids = [];
        this.selection.forEach(ele => {
          ids.push(ele[this.rowKey]);
        });
        return ids.join(",");
      },
      bindVal () {
        return {
          ref: 'crud',
          option: this.option,
          data: this.data,
          tableLoading: this.loading
        }
      },
      onEvent () {
        return {
          'selection-change': this.selectionChange,
          'on-load': this.getList,
          'row-save': this.rowSave,
          'row-update': this.rowUpdate,
          'row-del': this.rowDel,
          'refresh-change': this.refreshChange,
          'date-change': this.dateChange,
          'search-reset': this.searchChange,
          'search-change': this.searchChange
        }
      },
      rowKey () {
        return this.option.rowKey || option.rowKey || 'id'
      }
    },
    methods: {
      getList () {
        const callback = () => {
          this.loading = true;
          let pageParams = {}
          pageParams[option.pageSize || 'pageSize'] = this.page.pageSize
          pageParams[option.pageNum || 'pageNum'] = this.page.currentPage
          let data = Object.assign(pageParams, this.params)
          this.data = []
          this.api[option.list || 'list'](data).then(res => {
            this.loading = false;
            let data;
            if (option.res) {
              data = option.res(res.data);
            } else {
              data = res.data.data
            }
            this.page.total = data[option.total || 'total'] || 0;
            const result = data[option.data || 'data'];
            this.data = result;
            if (this.listAfter) {
              this.listAfter(data)
            } else {
              this.$message.success('获取成功')
            }
          })
        }
        if (this.listBefore) {
          this.listBefore()
        }
        callback()
      },
      rowSave (row, done, loading) {
        const callback = () => {
          this.api[option.add || 'add'](row).then((data) => {
            this.getList();
            if (this.addAfter) {
              this.addAfter(data)
            } else {
              this.$message.success('新增成功')
            }
            done();
          }).catch(() => {
            loading()
          })
        }
        if (this.addBefore) {
          this.addBefore()
        }
        callback()
      },
      rowUpdate (row, index, done, loading) {
        const callback = () => {
          this.api[option.update || 'update'](row[this.rowKey], row, index).then((data) => {
            this.getList();
            if (this.updateAfter) {
              this.updateAfter(data)
            } else {
              this.$message.success('更新成功')
            }
            done()
          }).catch(() => {
            loading()
          })
        }
        if (this.updateBefore) {
          this.updateBefore()
        }
        callback()
      },
      rowDel (row, index) {
        const callback = () => {
          this.api[option.del || 'del'](row[this.rowKey], row).then((data) => {
            this.getList();
            if (this.delAfter) {
              this.delAfter(data, row, index)
            } else {
              this.$message.success('删除成功')
            }
          })
        }
        if (this.delBefore) {
          this.delBefore()
          callback()
        } else {
          this.$confirm(`此操作将永久删除序号【${index}】的数据, 是否继续?`, '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            callback()
          })
        }
      },
      searchChange (params, done) {
        params = this.search
        if (done) done();
        if (this.validatenull(params)) {
          Object.keys(this.params).forEach(ele => {
            if (!['createTime_dategt', 'createTime_datelt'].includes(ele)) {
              delete this.params[ele]
            }
          })
        } else {
          Object.keys(params).forEach(ele => {
            if (this.validatenull(params[ele])) {
              delete this.params[ele]
              delete params[ele];
            }
          })
        }
        this.params = Object.assign(this.params, params);
        this.page.currentPage = 1;
        this.getList();
      },
      dateChange (date) {
        if (date) {
          this.params.createTime_dategt = date[0]
          this.params.createTime_datelt = date[1]
        } else {
          delete this.params.createTime_dategt
          delete this.params.createTime_datelt
        }
        this.page.currentPage = 1;
        this.getList();
      },
      selectionChange (list) {
        this.selection = list;
      },
      refreshChange () {
        this.getList();
      }
    }
  }
  app.mixins = app.mixins || [];
  app.mixins.push(mixins)
  return app;
}