
import request from '@/utils/request'
import { url } from '@/config'
const baseUrl = url + '/visual-data/respond'
export const list = (data) => {
  return request({
    url: baseUrl + `/list`,
    method: 'get',
    params: data
  })
}
export const del = (id) => request.get(baseUrl + '/remove', {
  params: {
    ids: id
  }
})
export const update = (id, data) => request({
  url: baseUrl + '/upsert',
  method: 'post',
  data: data
})
export const add = (data) => update('', data)



