
import request from "@/utils/request"
import { config } from '@/option/config'
import { url } from '@/config'


export const getList = (params) => {
  return request.get(url + "/visual/list", {
    params: params
  })
}


export const copyObj = (id) => {
  return request({
    headers: { "Content-Type": "multipart/form-data" },
    url: url + "/visual/copy",
    method: 'post',
    params: {
      id: id
    }
  })
}



export const getCategory = (params) => {
  return request.get(url + "/org-manage/list", {
    params: params
  })
}
//转移数据接口
export const transferApi = (data) => {
  return request({
    url: url + `/visual/copyAll?sourceCategory=${data.activeName}&destCategory=${data.value}`,
    method: 'post'
  })
}
//发送图片接口
export const send3dImageNotice = (data) => {
  return request({
    url: url + `/ws/message/send3dImageNotice/${data.imgId}/${data.value}`,
    method: 'post'
  })
}

export const getObj = (id) => {
  return request.get(url + "/visual/detail", {
    params: {
      id
    }
  })
}

export const uploadImg = (file) => {
  return request.post(url + "/visual/put-file", {
    data: 'file=' + file,
    headers: { "Content-Type": "multipart/form-data" }
  })
}

export const list = (data) => {
  return request({
    url: url + `/conditions/list-page?page=${data.page}&size=${data.size}`,
    method: 'post',
    data: {
      "id": "",
      "orgId": "",
      "name": "",
      "condition": "",
      "frameId": "",
      "note": "",
      "init": 0
    }
  })
}
export const addObj = (data) => {
  return request({
    url: url + `/visual/save`,
    method: 'post',
    data: {
      visual: {
        password: data.password,
        category: data.category,
        status: data.status,
        title: data.title,
      },
      config: {
        detail: JSON.stringify(Object.assign(config, {
          name: data.title,
          width: data.width,
          height: data.height
        })),
        component: '[]'
      },
    }
  })
}


// export const addObj = (data) => request({
//   url: url + '/visual/save',
//   method: 'post',
//   data: {
//     visual: {
//       password: data.password,
//       category: data.category,
//       status: data.status,
//       title: data.title,
//     },
//     config: {
//       detail: JSON.stringify(Object.assign(config, {
//         name: data.title,
//         width: data.width,
//         height: data.height
//       })),
//       component: '[]'
//     },
//   }
// });

export const updateComponent = (data) => {
  return request.post(url + "/visual/update", data)
}

export const updateObj = (data) => {
  return request.post(url + "/visual/update", {
    "visual": data
  })
}
export const delObj = (id) => {
  return request({
    headers: { "Content-Type": "multipart/form-data" },
    url: url + "/visual/remove",
    method: 'post',
    params: {
      id: id
    }
  })
}

// export const delObj=(id)=>{
//   return request.post("/visual/remove",{
//     params: {
//       ids: id
//     }
//   })
// }


