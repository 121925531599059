import router from './routers'
import Config from '@/settings'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css'// progress bar style
import { getToken } from '@/utils/auth' // getToken from cookie
import { buildMenus } from '@/api/system/menu'
NProgress.configure({ showSpinner: false })// NProgress Configuration

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title + ' - ' + Config.title
  }
  NProgress.start()
  if (getToken()) {
    next()
  } else {
    if (to.path === '/login') {
      next()
    } else {
      next({
        path: '/login', query: {
          redirect: to.fullPath
        }
      })
    }
  }
  NProgress.done()
})
export const loadMenus = () => {
  buildMenus().then(res => {
    console.log(res)
  })
}

router.afterEach(() => {
  NProgress.done() // finish progress bar
})
