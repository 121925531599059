
import request from '@/utils/request'
import { url } from '@/config'
import Date from '@/utils/datetime.js'
export const list = (data) => {
  return request({
    url: url + `/conditions/list-page`,
    method: 'post',
    params: data,
    data: data
  })
}
export const del = (id) => request.delete(url + '/conditions/deletes', {
  params: {
    ids: id
  }
})
export const update = (id, data) => request({
  url: url + '/conditions/upsert',
  method: 'post',
  data: {
    ...data,
    endTime: new Date().strftime(),
    startTime: new Date().strftime(),
  }
})
export const add = (data) => update('', data)



