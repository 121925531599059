import request from '@/utils/request'
import { url } from '@/config'

export const drawImage = (data) => {
  return request.post(url + "/image/draw", data);
}

export const image2Avi = (data) => {
  return request.post(url + "/video/generate", data);
}
