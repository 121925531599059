export default {
  menuWidth: 180,
  translate: false,
  labelWidth: 130,
  selection: true,
  tip: false,
  index: true,
  height: 'auto',
  align: 'center',
  headerAlign: 'center',
  border: true,
  stripe: true,
  translate: false,
  calcHeight: 60,
  column: [{
    label: '隐患类型',
    prop: 'riskType',
    rules: [{
      required: true,
      message: "请输入隐患类型",
      trigger: "blur"
    }]
  }, {
    label: '所在乡镇',
    prop: 'villageTown',
    append: '区',
    rules: [{
      required: true,
      message: "请输入所在乡镇",
      trigger: "blur"
    }]
  }, {
    label: '所在行政村',
    prop: 'administrativeVillage',
    rules: [{
      required: true,
      message: "请输入所在乡镇（区）",
      trigger: "blur"
    }]
  }, {
    label: '具体地址',
    prop: 'address',
    overHidden: true,
    span: 24,
    rules: [{
      required: true,
      message: "请输入具体地址",
      trigger: "blur"
    }]
  }, {
    label: '影响户数',
    prop: 'affectFamily',
    append: '户',
    rules: [{
      required: true,
      message: "请输入影响户数",
      trigger: "blur"
    }]
  }, {
    label: '影响人数',
    prop: 'affectPerson',
    append: '人',
    rules: [{
      required: true,
      message: "请输入影响人数",
      trigger: "blur"
    }]
  }, {
    label: '转移安置地点',
    prop: 'relocationAddress',
    overHidden: true,
    span: 24,
    rules: [{
      required: true,
      message: "请输入转移安置地点",
      trigger: "blur"
    }]
  }, {
    label: '转移责任人',
    prop: 'relocationResponsible',
    rules: [{
      required: true,
      message: "请输入转移责任人",
      trigger: "blur"
    }]
  }, {
    label: '责任人手机号码',
    prop: 'responsiblePhone',
    rules: [{
      required: true,
      message: "请输入责任人手机号码",
      trigger: "blur"
    }]
  }, {
    label: '当前主要问题及隐患',
    prop: 'problemRisk',
    type: 'textarea',
    overHidden: true,
    span: 24,
    rules: [{
      required: true,
      message: "请输入当前主要问题及隐患",
      trigger: "blur"
    }]
  }, {
    label: '整改责任单位',
    prop: 'amendUnit',
    rules: [{
      required: true,
      message: "请输入整改责任单位",
      trigger: "blur"
    }]
  }, {
    label: '整改负责人及联系电话',
    prop: 'amendResponsiblePhone',
    rules: [{
      required: true,
      message: "请输入整改负责人及联系电话",
      trigger: "blur"
    }]
  }, {
    label: '整改措施',
    prop: 'amendMethod',
    overHidden: true,
    type: 'textarea',
    span: 24,
    rules: [{
      required: true,
      message: "请输入整改措施",
      trigger: "blur"
    }]
  }, {
    label: '整改进展情况',
    prop: 'amendProgress',
    type: 'textarea',
    span: 24,
    rules: [{
      required: true,
      message: "请输入整改进展情况",
      trigger: "blur"
    }]
  }]
}