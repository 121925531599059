import request from "@/utils/request"
import { url } from '@/config'
export const materialList = (params) => {
  return request.get(url + "/material/list", {
    params: params
  })
}

export const materialRemove = (params) => {
  return request.get(url + "/material/remove", {
    params: params
  })
}

export const materialStreamremove = (params) => {
  return request.get(url + "/material-stream/remove", {
    params: params
  })
}

export const materialStreamlist = (params) => {
  return request.get(url + "/material-stream/list", {
    params: params
  })
}

export const materialStreamupdate = (params) => {
  return request({
    url: url + '/material-stream/update',
    method: 'post',
    data: {
      ...params,
      ...{
        identify: "ys1",
        name: 'dsadsadsadsadssa',
        origin: "HAIKANG",
        status: "VALID",
      }
    }
  })
}

export const materialStreamsave = (params) => {
  return request({
    url: url + '/material-stream/save',
    method: 'post',
    data: {
      ...{
        expireDate: new Date(),
        expireDate: new Date(),
        origin: "HAIKANG",
      },
      ...params,
    }
  })
}


