import { url } from '@/config'
export default {
  menuWidth: 220,
  translate: false,
  searchLabelWidth: 100,
  dialogWidth: '30%',
  labelWidth: 120,
  selection: true,
  tip: false,
  index: true,
  height: 'auto',
  align: 'center',
  headerAlign: 'center',
  border: true,
  stripe: true,
  translate: false,
  calcHeight: 60,
  column: [{
    label: '角色名称',
    prop: 'name',
    span: 24,
    search: true,
    rules: [{
      required: true,
      message: "请输入角色名称",
      trigger: "blur"
    }]
  }, {
    label: '角色编码',
    prop: 'code',
    span: 24,
    search: true,
    rules: [{
      required: true,
      message: "请输入角色编码",
      trigger: "blur"
    }]
  }]
}