
import request from '@/utils/request'
import { url } from '@/config'
export const list = (data) => {
  return request({
    url: url + `/job-task-command/list-commands`,
    method: 'get',
    params: data
  })
}



