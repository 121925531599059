import { url } from '@/config'
export default {
  menuWidth: 180,
  translate: false,
  searchLabelWidth: 100,
  labelWidth: 120,
  selection: true,
  tip: false,
  index: true,
  align: 'center',
  headerAlign: 'center',
  border: true,
  stripe: true,
  translate: false,
  calcHeight: 60,
  column: [{
    label: '组织架构',
    prop: 'orgId',
    type: 'select',
    search: true,
    dataType: 'string',
    row: true,
    dicUrl: url + '/org-manage/list?size=100',
    dicFormatter: (data) => {
      return data.records
    },
    props: {
      label: 'name',
      value: 'id'
    },
    rules: [{
      required: true,
      message: "请选择组织架构",
      trigger: "blur"
    }]
  }, {
    label: '名称',
    prop: 'name',
    search: true,
    rules: [{
      required: true,
      message: "请输入名称",
      trigger: "blur"
    }]
  }, {
    label: '条件',
    prop: 'condition',
    search: true,
    rules: [{
      required: true,
      message: "请输入条件",
      trigger: "blur"
    }]
  }, {
    label: '大屏ID',
    prop: 'frameId',
    search: true,
    formslot: true,
    rules: [{
      required: true,
      message: "请输入大屏ID",
      trigger: "blur"
    }]
  },
  {
    label: '表达式',
    prop: 'express',
    type: 'textarea',
    span: 24,
  },
  {
    label: '指令类型',
    prop: 'newFlg',
    value: 0,
    type: 'radio',
    rules: [{
      required: true,
      message: "请选择指令类型",
      trigger: "blur"
    }],
    dicData: [{
      label: '旧',
      value: 0
    }, {
      label: '新',
      value: 1
    }]
  },
  {
    label: '配置',
    prop: 'config',
    type: 'textarea',
    span: 24,
  },
  {
    // initinitinitinit
    label: 'init',
    prop: 'init',
    search: true,
    formslot: true,
    rules: [{
      required: true,
      message: "请输入大屏ID",
      trigger: "blur"
    }]
  }, {
    label: '说明',
    prop: 'note',
    search: true,
    type: 'textarea',
    overHidden: true,
    span: 24,
    rules: [{
      required: true,
      message: "请输入说明",
      trigger: "blur"
    }]
  }]
}