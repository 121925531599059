import axios from 'axios'
import { Message } from 'element-ui';
import { getToken,getCookieWithExpireTime } from '@/utils/auth'
import Config from '@/settings'
import qs from 'qs'
import store from '@/store';
window.$glob = {
  url: '',
  params: {},
  query: {},
  headers: {}
};

axios.defaults.validateStatus = function (status) {
  return status >= 200 && status <= 500; // 默认的
};
// 创建axios实例
const service = axios.create({

  timeout: Config.timeout, // 请求超时时间
  withCredentials: true
})
axios.defaults.withCredentials = true;
// request拦截器
service.interceptors.request.use(
  config => {
    if (getToken()) {
      config.headers['Authorization'] = getToken()
      if(getCookieWithExpireTime()){
        store.dispatch('refreshHnvjd')
      }
    }
    
    if(store.getters.hnvjdToken){
      config.headers['Cookie'] = "ASP.NET_SessionId="+store.getters.hnvjdToken;
    }
    if (config.headers.isSerialize && ['post', 'put'].includes(config.method)) {
      config.data = qs.stringify(config.data)
      delete config.headers.isSerialize
    }
    return config
  },
  error => {
    Promise.reject(error)
  }
)

// response 拦截器
service.interceptors.response.use(
  response => {
    let code = response.status
    const url = response.config.url;
    if ([401].includes(code) && url.indexOf('/login') != -1) {
      return Promise.reject()
    } else if ([401].includes(code)) {
      window.location.replace(process.env.VUE_APP_BASE_URL + 'login?redirect=' + window.location.pathname)
    } else if ([403].includes(code)) {
      Message({
        type: 'error',
        message: '操作没有权限，请联系管理员'
      })
      return Promise.reject()
    } else {
      code = response.data.code
      if (code && code !== 200) {
        Message({
          type: 'error',
          message: '操作失败，请联系管理员'
        })
        return Promise.reject()
      }
    }
    return response.data
  }, error => {
    return Promise.reject(error)
  }
)
export default service

export const base_url = process.env.VUE_APP_BASE_API;
