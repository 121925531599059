
import request from '@/utils/request'
import { url } from '@/config'
export const list = (data) => {
  return request({
    url: url + '/authority/permission/list',
    method: 'post',
    params: data
  })
}
export const del = (ids) => request({
  url: url + '/authority/permission/delete/' + ids,
  method: 'post'
})
export const add = (data) => request({
  url: url + '/authority/permission/upsert',
  method: 'post',
  data: data
})
export const detail = (ids) => request({
  url: url + '/authority/permission/detail/' + ids,
  method: 'post'
})
export const update = (id, data) => add(data);

export const bind = (id, data) => request({
  url: url + '/authority/permission/bind/' + id,
  method: 'post',
  data: data
})



