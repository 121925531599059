import { login, getInfo, logout,getHnvjdToken} from '@/api/login'
import { getToken, setToken, removeToken,setHnvjdToken,removeHnvjdToken } from '@/utils/auth'
import store from 'store2'

const user = {
  state: {
    token: getToken(),
    hnvjdToken:store.get('hnvjdToken') ,
    user: store.get('user') || {},
    roles: store.get('roles') || [],
    permission: store.get('permission') || [],
    // 第一次加载菜单时用到
    loadMenus: false,
    controlDulp:false,
  },

  mutations: {
    SET_hnvjdToken: (state, hnvjdToken) => {
      state.hnvjdToken = hnvjdToken
      setHnvjdToken(hnvjdToken)
      state.hnvjdToken = hnvjdToken;
      store.set('hnvjdToken', hnvjdToken)
    },
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_PERMISSION: (state, permission) => {
      state.permission = permission
      store.set('permission', permission)
    },
    SET_USER: (state, user) => {
      state.user = user
      store.set('user', user)
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles
      store.set('roles', roles)
    },
    SET_LOAD_MENUS: (state, loadMenus) => {
      state.loadMenus = loadMenus
    }
  },

  actions: {
    refreshHnvjd({ state,commit }) {
      if(state.controlDulp) return;
      return new Promise((resolve, reject) => {
        state.controlDulp = true
        getHnvjdToken().then(res=>{
          commit('SET_hnvjdToken',res.data.split('=')[1]);
          state.controlDulp = false
          resolve()
        }).catch(error => {
          state.controlDulp = false
          reject(error)
        })
      })
      
    },
    // 登录
    Login ({ commit, dispatch }, userInfo) {
      return new Promise((resolve, reject) => {
        login(userInfo.username, userInfo.password).then(res => {
          commit('SET_LOAD_MENUS', true)
          return dispatch('GetInfo')
        }).then(res => {
          resolve(res)
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 获取用户信息
    GetInfo ({ commit }) {
      return new Promise((resolve, reject) => {
        getInfo().then(res => {
          let list = res.data.roles || [];
          let permission = [], roles = [];
          list.forEach(ele => {
            (ele.permissions || []).forEach(item => {
              permission.push(item.code)
            })
          })
          let id = res.data.id
          setToken(id)
          commit('SET_TOKEN', id)
          commit('SET_USER', res.data)
          commit('SET_ROLES', roles)
          commit('SET_PERMISSION', permission)
          getHnvjdToken().then(hnvjdTokenRes=>{
            console.log(hnvjdTokenRes.data)
            commit('SET_hnvjdToken', hnvjdTokenRes.data.split('=')[1])
            resolve(res)
          })
          
        }).catch(error => {
          reject(error)
        })
      })
    },
    // 登出
    LogOut ({ commit }) {
      return new Promise((resolve, reject) => {
        logout().then(() => {
          commit('SET_HnvjdToken', '')
          commit('SET_TOKEN', '')
          commit('SET_USER', {})
          commit('SET_ROLES', [])
          commit('SET_PERMISSION', [])
          removeToken()
          removeHnvjdToken()
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    updateLoadMenus ({ commit }) {
      return new Promise((resolve, reject) => {
        commit('SET_LOAD_MENUS', false)
      })
    }
  }
}

export default user
