import Cookies from 'js-cookie'
import Config from '@/settings'

const TokenKey = Config.TokenKey

export function getToken () {
  return Cookies.get(TokenKey)
}

export function setToken (token) {
  Cookies.set(TokenKey, token)
}

export function removeToken () {
  return Cookies.remove(TokenKey)
}

export function setHnvjdToken (hnvjdtoken) {
  Cookies.set('ans_expires_time',(new Date().getTime()+15000));
  return Cookies.set('ASP.NET_SessionId', hnvjdtoken, { path: '/' })//一小时过期
}

export function getHnvjdToken () {
  return Cookies.get('ASP.NET_SessionId')
}

export function removeHnvjdToken () {
  return Cookies.remove('ASP.NET_SessionId')
}

export function getCookieWithExpireTime() {
  
  const expireString = Cookies.get('ans_expires_time')
  if(!expireString) return true;
  // if(!Cookies.get('ASP.NET_SessionId')) return true;
  if (new Date().getTime() > expireString) {
      console.log("Cookie已过期")
      return true
  } else {
      console.log("Cookie未过期")
      return false
  }
}