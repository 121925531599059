
import request from '@/utils/request'
import { url } from '@/config'
import Date from '@/utils/datetime.js'
export const list = (data,searchData) => {
  console.log(data)
  console.log(searchData)
  return request({
    url: url + `/job-task-command/page-tasks`,
    method: 'POST',
    params:data,
    headers: {
      'Content-Type': 'application/json'
    },
    data:JSON.stringify(searchData)
  })
}
export const del = (id) => request.delete(url + '/job-task-command/delete-task/'+id)



export const update = (data) => request({
  url: url + '/job-task-command/upsert-task',
  method: 'POST',
  data:data
})
export const add = (data) => update('', data)

export const templateUserList = (data) => {
  return request({
    url: url + `/template-user/page`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    data
  })
}
export const getTemplateUser = (data) => {
  return request({
    url: url + `/template-user/list`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    data
  })
}

