import request from "@/utils/request"
import { url } from '@/config'
let s = url + "/file/"
export const getList = (data) => {
  return request.get(s + "getDir", {
    params: data
  })
}

export const delFile = (path) => {
  return request.delete(s + "delFile", {
    params: { path }
  })
}

export const reNameFile = ({ path, value }) => {
  return request.post(s + "reName", { path, name: value })
}
export const zip = ({ path, value }) => {
  return request.post(s + "zip", { path, name: value })
}
export const unzip = ({ path, value }) => {
  return request.post(s + "unZip", { path, name: value })
}

export const show3d = ({ groupCode, fileName }) => {
  return request.post("zhdt/show3d", { groupCode, fileName })
}

export const closeHttpUrl = ({ groupCode }) => {
  return request.post("zhdt/closeHttpUrl", { groupCode })
}