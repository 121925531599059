import request from "@/utils/request"
import { url } from '@/config'
export const recordlist = (data) => {
  return request.get(url + '/api-record/list', {
    params: data
  });
}
// export const recordlist = (data) => {
//   return request({
//     url: url + '/api-record/list',
//     method: 'get',
//     params: data
//   })
// }

export const detail = (data) => {
  return request.get(url + '/api-record/detail', {
    params: data
  });
}


// export const list = (data) => {
//     return request({
//       url: url + '/material-stream/list',
//       method: 'get',
//       params: data
//     })
  // }



