import { url } from '@/config'
export default {
  menuWidth: 180,
  translate: false,
  searchLabelWidth: 100,
  labelWidth: 120,
  selection: true,
  tip: false,
  index: true,
  align: 'center',
  headerAlign: 'center',
  border: true,
  stripe: true,
  translate: false,
  calcHeight: 60,
  saveBtn:false,
  updateBtn:false,
  cancelBtn:false,
  column: [{
    label: 'job名称',
    prop: 'name',
    search: true,
    rules: [{
      required: true,
      message: "请输入job名称",
      trigger: "blur"
    }]
  }, 
  {
    label: '组织',
    prop: 'orgId',
    search: true,
    searchslot:true,
    display:false,
    hide:true,
    showColumn:false
  }, {
    label: '任务名称',
    prop: 'taskName',
    search: true,
    display:false,
    hide:true,
    showColumn:false
  }, {
    label: '命令动作',
    prop: 'command',
    search: true,
    searchslot: true,
    display:false,
    hide:true,
    showColumn:false
  }, {
    label: 'job描述',
    prop: 'desc'
  }, {
    label: '组织',
    prop: 'jobOrgDtos',
    span: 24,
    formslot:true,
    formatter: (row, val) => {
      let str = ''
      if(val){
        for(let i=0;i<val.length;i++){
          if(i!=val.length-1){
            str = str + val[i].name +','
          }else{
            str += val[i].name
          }
        }
      }
      
      return str
    }
  }, {
    label: '任务列表',
    prop: 'taskDtos',
    formslot:true,
    span: 24,
    formatter: (row, val) => {
      let str = ''
      if(val){
        for(let i=0;i<val.length;i++){
          if(i!=val.length-1){
            str = str + val[i].name +','
          }else{
            str += val[i].name
          }
        }
      }
      return str
    }
  },{
    label: '命令动作列表',
    prop: 'taskDtos',
    display:false,
    formatter: (row, val) => {
      let str = ''
      if(val){
        for(let i=0;i<val.length;i++){
          if(i!=val.length-1){
            str = str + val[i].commandDesc +','
          }else{
            str += val[i].commandDesc
          }
        }
      }
      return str
    }
  }]
}