import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router/routers'
import './router/index'
import AES from '@/utils/aes'
import crudCommon from '@/mixins/avue'
import request from '@/utils/request'
import Element from 'element-ui'
import './assets/icons' // icon
import 'element-ui/lib/theme-chalk/index.css';
import './assets/styles/index.scss'
import './styles/ic_ce.scss'
import Avue from '@smallwei/avue';
import '@smallwei/avue/lib/index.css';
import { $website } from './config.js'
Vue.prototype.$website = $website;
Vue.config.productionTip = true
window.axios = request;
Vue.prototype.$axios = request;
window.$crudCommon = crudCommon
Vue.use(AES)
Vue.use(Avue);
Vue.use(Element, {
  size: 'small' // set element-ui default size
})
new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app')
