import { url } from '@/config'
export default {
  menuWidth: 180,
  translate: false,
  searchLabelWidth: 100,
  labelWidth: 120,
  selection: true,
  tip: false,
  index: true,
  align: 'center',
  headerAlign: 'center',
  border: true,
  stripe: true,
  translate: false,
  column: [{
    label: '组织架构',
    prop: 'orgId',
    type: 'select',
    search: true,
    dicUrl: url + '/org-manage/list?size=100',
    dicFormatter: (data) => {
      return data.records
    },
    props: {
      label: 'name',
      value: 'id'
    },
    rules: [{
      required: true,
      message: "请选择组织架构",
      trigger: "blur"
    }]
  }, {
    label: '姓名',
    prop: 'name',
    rules: [{
      required: true,
      message: "请输入姓名",
      trigger: "blur"
    }]
  }, {
    label: '职位',
    prop: 'position',
    search: true,
    rules: [{
      required: true,
      message: "请输入职位",
      trigger: "blur"
    }]
  }, {
    label: '手机号',
    prop: 'phone',
    search: true,
    rules: [{
      required: true,
      message: "请输入手机号",
      trigger: "blur"
    }]
  }, {
    label: '局办',
    prop: 'office',
    type: 'select',
    dicUrl: url + '/template/dict?dictTypeCode=SHUANGLIAO_OFFICE',
    search: true,
    props: {
      label: 'name',
      value: 'code'
    },
    rules: [{
      required: true,
      message: "请选择局办",
      trigger: "blur"
    }]
  }, {
    label: '值班时间',
    prop: 'dutyDate',
    type: 'checkbox',
    dataType: 'string',
    span: 24,
    dicData: [{
      label: '周一',
      value: '周一'
    }, {
      label: '周二',
      value: '周二'
    }, {
      label: '周三',
      value: '周三'
    }, {
      label: '周四',
      value: '周四'
    }, {
      label: '周五',
      value: '周五'
    }, {
      label: '周六',
      value: '周六'
    }, {
      label: '周日',
      value: '周日'
    }],
    rules: [{
      required: true,
      message: "请选择值班时间",
      trigger: "blur"
    }]
  }, {
    label: '离岗',
    prop: 'isAbsences',
    type: 'select',
    search: true,
    dicData: [{
      label: '是',
      value: 1
    }, {
      label: '否',
      value: 0
    }],
    rules: [{
      required: true,
      message: "请选择离岗",
      trigger: "blur"
    }]
  }, {
    label: '主要负责任',
    prop: 'isPrincipal',
    type: 'select',
    search: true,
    dicData: [{
      label: '是',
      value: 1
    }, {
      label: '否',
      value: 0
    }],
    rules: [{
      required: true,
      message: "请选择主要负责任",
      trigger: "blur"
    }]
  }, {
    label: '备注',
    prop: 'remark',
    span: 24,
    type: 'textarea',
  }, {
    label: '模板',
    prop: 'temp',
    span: 24,
    hide: true,
    addDisplay: false
  }]
}