<template>
  <div :class="classObj"
       class="app-wrapper">
    <div v-if="device === 'mobile' && sidebar.opened"
         class="drawer-bg"
         @click="handleClickOutside" />
    <sidebar class="sidebar-container" />
    <div :class="{ hasTagsView: needTagsView }"
         class="main-container">
      <div :class="{ 'fixed-header': fixedHeader }">
        <navbar />
        <tags-view v-if="needTagsView" />
      </div>
      <app-main />
      <right-panel v-if="showSettings">
        <settings />
      </right-panel>
    </div>
    <div class="themefixed"
         @click="drawer = true">测试调用</div>
    <el-drawer title="调用线上微信指令（演示期间谨慎使用）"
               size="60%"
               custom-class="httpDrawer"
               :visible.sync="drawer"
               direction="rtl">
      <el-form ref="form"
               :model="search"
               :rules="rules">
        <el-form-item prop="orgId">
          <el-select style="width:120px"
                     v-model="search.orgId"
                     clearable
                     placeholder="请选择组织">
            <template v-for="(item, i) in orgs">
              <el-option :value="item.appId+'@@'+item.code"
                         :label="item.name"
                         :key="i"></el-option>
            </template>
          </el-select>
        </el-form-item>
        <el-form-item prop="url">
          <el-select style="width:250px"
                     @change="drawerModel"
                     v-model="search.url"
                     clearable
                     placeholder="测试功能">
            <template v-for="(item, i) in urls">
              <el-option :value="item.id"
                         :label="item.name"
                         :key="i"></el-option>
            </template>
          </el-select>
        </el-form-item>
        <el-button style="margin-left: 30px"
                   @click="execUrl"
                   type="primary">执行</el-button>
        <template v-if="param">
          <p style="font-size:14px;color:#aaa">输入指令</p>
          <el-form-item prop="content"
                        :rules="[{ required: true, message: '输入微信发送指令', trigger: 'blur' }]">
            <el-input v-model="search.content"
                      placeholder="请输入内容"></el-input>
          </el-form-item>
        </template>
        <p style="font-size:14px;color:#aaa">输出结果======================================</p>
        <div style="overflow:auto">
          <pre>{{jsonData}}</pre>
        </div>
      </el-form>
    </el-drawer>
  </div>
</template>

<script>
import RightPanel from "@/components/RightPanel";
import { AppMain, Navbar, Settings, Sidebar, TagsView } from "./components";
import ResizeMixin from "./mixin/ResizeHandler";
import { mapState } from "vuex";
import Cookies from "js-cookie";
import { orgList } from "@/api/casecondit";
import axios from 'axios'
export default {
  name: "Layout",
  data () {
    return {
      drawer: false,
      param: false,
      rules: {
        orgId: [
          { required: true, message: "请选择测试组织", trigger: "blur" },
        ],
        url: [
          { required: true, message: "请选择测试接口", trigger: "blur" },
        ],
      },
      search: {
        orgId: "",
        url: "",
      },
      orgs: [],
      urls: [
        {
          name: "启动应急预案",
          id: process.env.VUE_APP_BASE_API + "zhdt/[groupCode]/getlist?id=m@@get",
        },
        {
          name: "大屏页面展示验证接口",
          id: process.env.VUE_APP_BASE_API + "zhdt/[groupCode]/getContent?id=m@@get",
        },
        {
          name: "拼接器验证接口",
          id: process.env.VUE_APP_BASE_API + "zhdt/getCommand?groupCode=[groupCode]@@get",
        },
        {
          name: "浏览器3d模型验证接口",
          id: process.env.VUE_APP_BASE_API + "zhdt/getHttpUrl?groupCode=[groupCode]@@get",
        },
        {
          name: "微信公众号触发指令",
          id: process.env.VUE_APP_BASE_API + "zhdt/recvQuestion?appId=[appId]@@post",
        },
      ],
      http: axios.create({
        timeout: '20000', // 请求超时时间
        withCredentials: true
      }),
      jsonData: ""
    };
  },
  components: {
    AppMain,
    Navbar,
    RightPanel,
    Settings,
    Sidebar,
    TagsView
  },
  mixins: [ResizeMixin],
  computed: {
    ...mapState({
      sidebar: (state) => state.app.sidebar,
      device: (state) => state.app.device,
      showSettings: (state) => state.settings.showSettings,
      needTagsView: (state) => state.settings.tagsView,
      fixedHeader: (state) => state.settings.fixedHeader,
    }),
    classObj () {
      return {
        hideSidebar: !this.sidebar.opened,
        openSidebar: this.sidebar.opened,
        withoutAnimation: this.sidebar.withoutAnimation,
        mobile: this.device === "mobile",
      };
    },
  },
  created () {
    this.$store.dispatch('GetInfo')
  },
  mounted () {
    orgList({ size: 100 }).then((res) => {
      this.orgs = res.data.records;
    });
  },
  methods: {
    drawerModel (e) {
      const method = e.split("@@")[1]
      if (method == 'post') {
        this.param = true
      } else {
        this.param = false
      }
    },
    execUrl () {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          const groups = this.search.orgId.split("@@");
          const appId = groups[0]
          const groupCode = groups[1]
          const urls = this.search.url.replace(/\[.*\]/i, function (r) { return eval(r) }).split("@@")
          const url = urls[0]
          const method = urls[1]
          this.http({
            method,
            url,
            data: {
              content: this.search.content,
              msgType: 'text',
              fromUser: 'openid'
            }
          }).then(res => {
            this.jsonData = res.data;
          }).catch(e => {
            this.jsonData = e.statusText
          })
        } else {
          console.log("error submit!!");
          return false;
        }
      })
    },
    handleClickOutside () {
      this.$store.dispatch("app/closeSideBar", { withoutAnimation: false });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/mixin.scss";
@import "~@/assets/styles/variables.scss";

.themefixed {
  font-size: 15px;
  cursor: pointer;
  width: 25px;
  height: 90px;
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  border-radius: 5px;
  color: white;
  background-color: #bb6aab;
  padding: 5px;
  word-break: break-word;
}
::v-deep .httpDrawer {
  height: 100%;
  overflow: auto;
}
::v-deep .el-drawer__body {
  padding: 0 20px;
}
.app-wrapper {
  @include clearfix;
  position: relative;
  height: 100%;
  width: 100%;

  &.mobile.openSidebar {
    position: fixed;
    top: 0;
  }
}

.drawer-bg {
  background: #000;
  opacity: 0.3;
  width: 100%;
  top: 0;
  height: 100%;
  position: absolute;
  z-index: 999;
}

.fixed-header {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 9;
  width: calc(100% - #{$sideBarWidth});
  transition: width 0.28s;
  padding: 0;
}

.hideSidebar .fixed-header {
  width: calc(100% - 54px);
}

.mobile .fixed-header {
  width: 100%;
}
.el-form-item {
  display: inline-block;
}
</style>
