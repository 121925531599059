import Vue from 'vue'
import Router from 'vue-router'
import Layout from '../layout/index'
Vue.use(Router)
export const constantRouterMap = [
  {
    path: '/',
    component: Layout,
    redirect: '/conditions',
    meta: { title: '大屏工具', noCache: true, icon: "app", permission: 'M_SCREEN' },
    children: [
      {
        path: '/conditions',
        meta: { title: '指令', noCache: true, icon: 'app', permission: 'M_SCREEN_ZL' },
        name: "指令",
        component: (resolve) => require(['@/views/zhdt/conditions.vue'], resolve),
        hidden: false
      }, {
        path: 'data',
        component: () => import( /* webpackChunkName: "page" */ '@/page/index'),
        name: 'iframe',
        meta: { title: '设计器', icon: 'index', noCache: true, permission: 'M_SCREEN_SJQ' }
      }, {
        path: 'screens',
        component: (resolve) => require(['@/views/zhdt/screens'], resolve),
        name: 'iframe',
        meta: { title: '控制器', icon: 'index', noCache: true, permission: 'M_SCREEN_KZQ' }
      },
    ]
  },
  {
    path: '/',
    component: Layout,
    redirect: 'listCommands',
    meta: { title: '指令2.0', noCache: true, icon: "app", permission: 'M_SCREEN' },
    children: [
      {
        path: 'pageJobs',
        meta: { title: '指令', noCache: true, icon: 'app', permission: 'M_SCREEN_ZL' },
        name: "指令",
        component: (resolve) => require(['@/views/condition2.0/pageJobs.vue'], resolve),
        hidden: false
      },
      {
        path: 'pageTasks',
        meta: { title: '任务', noCache: true, icon: 'app', permission: 'M_SCREEN_ZL' },
        name: "任务",
        component: (resolve) => require(['@/views/condition2.0/pageTasks.vue'], resolve),
        hidden: false
      },
      {
        path: 'listCommands',
        meta: { title: '命令动作', noCache: true, icon: 'app', permission: 'M_SCREEN_ZL' },
        name: "命令动作",
        component: (resolve) => require(['@/views/condition2.0/listCommands.vue'], resolve),
        hidden: false
      }, 
      {
        path: 'screenTwo',
        meta: { title: '控制器', noCache: true, icon: 'app', permission: 'M_SCREEN_ZL' },
        name: "控制器",
        component: (resolve) => require(['@/views/zhdt/screensTwo.vue'], resolve),
        hidden: false
      }
    ]
  },
  {
    path: '/create',
    name: 'create',
    component: () =>
      import( /* webpackChunkName: "page" */ '@/page/create')
  }, {
    path: '/build/:id',
    name: 'build',
    component: () =>
      import( /* webpackChunkName: "page" */ '@/page/build')
  }, {
    path: '/view/:id',
    name: '测试',
    component: () =>
      import( /* webpackChunkName: "page" */ '@/page/view')
  },
  {
    path: "/screen",
    meta: { title: "大屏配置", noCache: true, icon: "app", permission: 'M_OPTION' },
    component: Layout,
    redirect: "img",
    alwaysShow: true,
    children: [
      //   {
      //   path: '/upload',
      //   meta: { title: '内容命名', noCache: true, icon: 'app' },
      //   name:"图片命名",
      //   component: (resolve) => require(['@/views/zhdt/screen.vue'], resolve),
      //   hidden: false
      // },

      {
        path: '/3D',
        meta: { title: '3D地图管理', noCache: true, icon: 'app', permission: 'M_OPTION_3D' },
        name: "3D地图管理",
        component: (resolve) => require(['@/views/zhdt/3D.vue'], resolve),
        hidden: false
      },
      {
        path: '/user',
        meta: { title: '模板用户管理', noCache: true, icon: 'app', permission: 'M_OPTION_MBYHGL' },
        name: "模板用户管理",
        component: (resolve) => require(['@/views/zhdt/user.vue'], resolve),
        hidden: false
      },
      // {
      //   path: '/img',
      //   meta: { title: '图片管理', noCache: true, icon: 'app', permission: 'M_OPTION_TPGL' },
      //   name: "图片管理",
      //   component: (resolve) => require(['@/views/zhdt/img.vue'], resolve),
      //   hidden: false
      // },
      {
        path: 'module',
        meta: { title: '功能模块', noCache: true, icon: 'app', permission: 'M_OPTION_MKGL' },
        name: '功能模块',
        component: (resolve) => require(['@/views/casecondit/module.vue'], resolve),
        hidden: false
      },
      {
        path: 'method',
        meta: { title: '处理方法', noCache: true, icon: 'app', permission: 'M_OPTION_CLFF' },
        name: "处理方法",
        component: (resolve) => require(['@/views/casecondit/method.vue'], resolve),
        hidden: false
      },
      {
        path: 'case',
        meta: { title: '条件判断执行', noCache: true, icon: 'app', permission: 'M_OPTION_TJPD' },
        name: "条件判断执行",
        component: (resolve) => require(['@/views/casecondit/case.vue'], resolve),
        hidden: false
      }
    ]
  },
  {
    path: '/systemUtil',
    meta: { title: '系统工具', noCache: true, icon: 'app', permission: 'M_TOOL' },
    // component: (resolve) => require(['@/views/template/index.vue'], resolve),
    // redirect:'/dd',
    component: Layout,
    alwaysShow: true,
    hidden: false,
    children: [
      // {
      //   path: 'video',
      //   meta: { title: '视频处理', noCache: true, icon: 'app', permission: 'M_TOOL_SPCL' },
      //   name: "视频处理",
      //   component: (resolve) => require(['@/views/video/index.vue'], resolve),
      //   hidden: false
      // },
      {
        path: 'Thirdpartyrecords',
        meta: { title: '异常调用', noCache: true, icon: 'app', permission: 'M_TOOL_YCDY' },
        name: "异常调用",
        component: (resolve) => require(['@/views/Thirdpartyrecords/index.vue'], resolve),
        hidden: false
      },
      {
        path: 'Materiallibrary',
        meta: { title: '素材库', noCache: true, icon: 'app', permission: 'M_TOOL_SCCK' },
        name: "素材库",
        component: (resolve) => require(['@/views/Thirdpartyrecords/Materiallibrary.vue'], resolve),
        hidden: false
      },
      {
        path: 'cameracontroller',
        meta: { title: '视频录制', noCache: true, icon: 'app', permission: 'M_TOOL_SPLZ' },
        name: "视频录制",
        component: (resolve) => require(['@/views/cameracontroller/index.vue'], resolve),
        hidden: false
      }
    ]
  },
  {
    path: '/system',
    meta: { title: '系统设置', noCache: true, icon: 'app', permission: 'M_SYS' },
    // component: (resolve) => require(['@/views/template/index.vue'], resolve),
    redirect: 'system/params',
    component: Layout,
    alwaysShow: true,
    hidden: false,
    children: [
      {
        path: 'org',
        meta: { title: '组织架构', noCache: true, icon: 'app', permission: 'M_SYS_ORG' },
        name: "组织架构",
        component: (resolve) => require(['@/views/sys/org.vue'], resolve),
        hidden: false
      },
      {
        path: 'user',
        meta: { title: '用户管理', noCache: true, icon: 'app', permission: 'M_SYS_USER' },
        name: "用户管理",
        component: (resolve) => require(['@/views/sys/user.vue'], resolve),
        hidden: false
      },
      {
        path: 'role',
        meta: { title: '角色管理', noCache: true, icon: 'app', permission: 'M_SYS_ROLE' },
        name: "角色管理",
        component: (resolve) => require(['@/views/sys/role.vue'], resolve),
        hidden: false
      },
      {
        path: 'permission',
        meta: { title: '权限管理', noCache: true, icon: 'app', permission: 'M_SYS_PERMISSION' },
        name: "权限管理",
        component: (resolve) => require(['@/views/sys/permission.vue'], resolve),
        hidden: false
      },
      {
        path: 'params',
        meta: { title: '参数管理', noCache: true, icon: 'app', permission: 'M_SYS_PARAM' },
        name: "参数管理",
        component: (resolve) => require(['@/views/system/param.vue'], resolve),
        hidden: false
      },
    ]
  },
  {
    path: '/login',
    meta: { title: '登录', noCache: true },
    component: (resolve) => require(['@/views/login'], resolve),
    hidden: true
  },
  {
    path: '/404',
    component: (resolve) => require(['@/views/features/404'], resolve),
    hidden: true
  },
  {
    path: '/401',
    component: (resolve) => require(['@/views/features/401'], resolve),
    hidden: true
  },
  {
    path: '/redirect',
    component: Layout,
    hidden: true,
    children: [
      {
        path: '/redirect/:path*',
        component: (resolve) => require(['@/views/features/redirect'], resolve)
      }
    ]
  }, 
  // {
  //   path: '/house',
  //   name: '海口房屋',
  //   component: Layout,
  //   meta: { title: '海口房屋', noCache: true, icon: "app", permission: 'M_HOUSE' },
  //   alwaysShow: true,
  //   hidden: false,
  //   children: [
  //     {
  //       path: 'map',
  //       name: '地图数据管理',
  //       meta: { title: '地图数据管理', noCache: true, icon: "app" },
  //       component: (resolve) => require(['@/views/house/map'], resolve)
  //     },
  //     {
  //       path: 'city',
  //       name: '城镇自建房屋管理',
  //       meta: { title: '城镇自建房屋管理', noCache: true, icon: "app" },
  //       component: (resolve) => require(['@/views/house/city'], resolve)
  //     },
  //     {
  //       path: 'town',
  //       name: '农村自建房屋管理',
  //       meta: { title: '农村自建房屋管理', noCache: true, icon: "app" },
  //       component: (resolve) => require(['@/views/house/town'], resolve)
  //     }, {
  //       path: 'flood',
  //       name: '龙华区汛前风险管理',
  //       meta: { title: '龙华区汛前风险管理', noCache: true, icon: "app" },
  //       component: (resolve) => require(['@/views/house/flood'], resolve)
  //     }
  //   ]
  // }
]

export default new Router({
  mode: 'history',
  base: process.env.VUE_APP_BASE_URL,
  // mode: 'history',
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRouterMap
})
