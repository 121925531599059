
import request from '@/utils/request'
import { url } from '@/config'
export const list = (data) => {
  return request({
    url: url + '/users/list',
    method: 'get',
    params: data
  })
}
export const del = (ids) => request({
  url: url + '/users/delete/' + ids,
  method: 'delete'
})
export const add = (data) => request({
  url: url + '/users/create',
  method: 'post',
  data: data
})

export const detail = (ids) => request({
  url: url + '/users/detail/' + ids,
  method: 'get',
})
export const update = (id, data) => request.post(url + "/users/upsert", data)
