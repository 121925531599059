import { url } from '@/config'
export default {
  menuWidth: 180,
  translate: false,
  labelWidth: 130,
  selection: true,
  tip: false,
  index: true,
  height: 'auto',
  align: 'center',
  headerAlign: 'center',
  border: true,
  stripe: true,
  translate: false,
  calcHeight: 60,
  column: [{
    label: '组织',
    prop: 'orgId',
    search: true,
    dataType: 'string',
    type: 'select',
    dicUrl: url + '/org-manage/list?size=100',
    dicFormatter: (data) => {
      return data.records
    },
    props: {
      label: 'name',
      value: 'id'
    },
    rules: [{
      required: true,
      message: "请选择组织",
      trigger: "blur"
    }]
  }, {
    label: '镇',
    prop: 'field1',
    rules: [{
      required: true,
      message: "请输入镇",
      trigger: "blur"
    }]
  }, {
    label: '状态',
    prop: 'respondStatus',
    type: 'select',
    dataType: 'number',
    dicData: [{
      label: '未响应',
      value: 0
    }, {
      label: '待命',
      value: 1
    }, {
      label: '响应',
      value: 2
    }]
  }, {
    label: '语音文字',
    prop: 'ttsText',
    type: 'textarea',
    span: 24
  }]
}