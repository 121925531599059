import request from "@/utils/request"
import { url } from '@/config'
export const cameraList = (data) => {
  return request.get(url + '/camera/list', {
    params: data
  });
}

export const camerasave = (data) => {
  return request({
    url: url + "/camera/save",
    method: 'post',
    data: data
  })
}

export const camerupdate = (data) => {
  return request({
    url: url + "/camera/update",
    method: 'post',
    data: data,
  })
}

export const cameraremove = (data) => {
  return request({
    url: url + "/camera/remove",
    method: 'post',
    params: data,
  })
}


export const recordDemoasyncreset = (data) => {
  return request({
    url: url + "/record-demo/async/reset",
    method: 'get',
    params: data,
  })
}

export const recordDemoasyncstart = (data) => {
  return request({
    url: url + "/record-demo/async/start",
    method: 'get',
    params: data,
  })
}

export const recordDemoasyncstop = (data) => {
  return request({
    url: url + "/record-demo/async/stop",
    method: 'get',
    params: data,
  })
}

export const livingStart = (data) => {
  return request({
    url: url + "/video-stream/async/start",
    method: 'get',
    params: data
  })
}

export const livingReset = (data) => {
  return request({
    url: url + "/video-stream/async/reset",
    method: 'get',
    params: data,
  })
}


export const livingStop = (data) => {
  return request({
    url: url + "/video-stream/async/stop",
    method: 'get',
    params: data,
  })
}
