
import request from '@/utils/request'
import { url } from '@/config'
export function login (username, password) {
  return request({
    url: url + '/login',
    method: 'post',
    headers: {
      isSerialize: true,
    },
    data: {
      username,
      password
    }
  })
}


export const getHnvjdToken = () => request({
  url: url + '/third-part/getHnvjdSession',
  method: 'get',
})

export const getInfo = () => request({
  url: url + '/users/detail/current-user',
  method: 'get',
})
export function getCodeImg () {
  return request({
    url: url + '/auth/code',
    method: 'get'
  })
}

export function logout () {
  return request({
    url: url + '/logout',
    method: 'post'
  })
}
