import request from "@/utils/request"
import { url } from '@/config'
export const getRoleList = (data) => {
  return request.get(url + '/role/getList', {
    params: data
  });
}
export const getMenuList = (data) => {
  return request.get(url + '/menu/getList', {
    params: data
  });
}
export const getAllUrl = () => {
  return request.get(url + "/menu/getAllUrl")
}
export const getRoleTree = () => {
  return request.get(url + '/role/getTree');
}
export const getAllRoleById = (data) => {
  return request.get(url + '/role/getAllRole', {
    params: data
  });
}
export const getRoleByUserId = (data) => {
  return request.get(url + '/user/getRoleByUserId', {
    params: data
  });
}
export const getAllRoles = () => {
  return request.get(url + '/user/getAllRoles', {});
}

export const getUserAndRoleList = () => {
  return request.get(url + '/user/getUserAndRoleList', {});
}
export const bindRole2User = (data) => {
  return request.post(url + '/user/bindRole2User/' + data.userId, data.roleId);
}

export const resetPassword = (data) => {
  return request.post(url + "/user/resetPassword", data, {
    headers: {
      isSerialize: true
    }
  })
}
export const bindUserRole = (data) => {
  return request.post(url + "/user/bindRole", data, {
    headers: {
      isSerialize: true
    }
  })
}

export const roleAdd = (data) => {
  return request.post(url + "/role/add", data)
}
export const roleUpdate = (data) => {
  return request.post(url + "/role/update", data)
}

export const roleDel = (data) => {
  return request.delete(url + "/role/del/" + data);
}

export const roleAddParent = (data) => {
  return request.post(url + "/role/addParentRole", data, {
    headers: {
      isSerialize: true
    }
  })
}

export const roleAddChild = (data) => {
  return request.post(url + "/role/addChildRole", data, {
  })
}

export const menuAdd = (data) => {
  return request.post(url + "/menu/add", data)
}
export const menuUpdate = (data) => {
  return request.post(url + "/menu/update", data)
}

export const menuDel = (data) => {
  return request.delete(url + "/menu/del/" + data);
}
export const menuAll = () => {
  return request.get(url + "/menu/getMenuAll")
}
//自动更新服务已有的api接口值
export const menuUpdataAllByService = (data) => {
  return request.post(url + "/menu/updataAllByService", {
    params: data
  })
}
export const getRoleResourcesById = (data) => {
  return request.get(url + '/role/getRoleResourcesById', {
    params: data
  });
}
export const bindRoleResource = (data) => {
  return request.post(url + "/role/bindRoleResource", data, {
    headers: {
      isSerialize: true
    }
  })
}
