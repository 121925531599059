import request from "@/utils/request"
import { url } from '@/config'
export const getList = (data) => {
  return request.get(url + '/user/getList', {
    params: data
  });
}

export const userAddOrUp = (data) => {
  return request.post(url + "/user/addOrUp", data)
}

export const userDel = (data) => {
  return request.delete(url + "/del/" + data);
}