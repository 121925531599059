
import request from '@/utils/request'
import { url } from '@/config'

export const getTemp = (userId) => {
  return request({
    url: url + '/template/templates',
    method: 'get',
    params: {
      userId
    }
  })
}
export const delTemp = (caseIds, userId) => request.delete(url + '/template/delTemplates', {
  params: {
    caseIds,
    userId
  }
})
export const addTemp = (data) => request({
  url: url + '/template/addTemplates',
  method: 'post',
  data: data
})
export const list = (data) => {
  let searchObj = {
    size: data.size?data.size:10,
    page: data.currentPage?data.currentPage:1,
    orgId: data.orgId?data.orgId:'',
    position: data.position?data.position:'',
    phone: data.phone?data.phone:'',
    office: data.office?data.office:'',
    isAbsences: data.isAbsences|| data.isAbsences ==0?data.isAbsences:'',
    isPrincipal: data.isPrincipal || data.isPrincipal==0?data.isPrincipal:''
  }
  return request({
    url: url + '/template/users',
    method: 'get',
    params: searchObj
  })
}
export const del = (ids) => request.delete(url + '/template/del', {
  params: {
    ids
  }
})
export const add = (data) => request({
  url: url + '/template/save',
  method: 'post',
  data: data
})
export const update = (id, data) => request({
  url: url + '/template/save',
  method: 'post',
  data: data
})



