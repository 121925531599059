import request from '@/utils/request'
import { url } from '@/config'
let common = url + "/param/"
let org = url + "/org/"
export const paramList = (data) => {
  return request.get(common + "getList", {
    params: data
  });
}

export const paramAddOrUp = (data) => {
  return request.post(common + "addOrUp", data)
}

export const paramDel = (data) => {
  return request.delete(common + "del/" + data);
}

export const orgList = (data) => {
  return request.get(org + "getList", {
    params: data
  });
}

export const orgAddOrUp = (data) => {
  return request.post(org + "addOrUp", data)
}

export const orgDemo = (data) => {
  return request.post(org + "isDemp", {
    id: data.id,
    isDemo: data.isDemo
  })
}


export const orgDel = (data) => {
  return request.delete(org + "del/" + data);
}