import CryptoJS from 'crypto-js'
const globalLey = CryptoJS.enc.Utf8.parse('lsdfKGDIGAis8w2d') // 十六位十六进制数作为密钥
const globalIv = CryptoJS.enc.Utf8.parse('') // 十六位十六进制数作为密钥偏移量
// 解密方法
function Decrypt(srcs, key, iv) {
  key = key ? CryptoJS.enc.Utf8.parse(key) : globalLey
  iv = iv ? CryptoJS.enc.Utf8.parse(iv) : globalIv
  const decrypt = CryptoJS.AES.decrypt(srcs, key, {
    iv: iv,
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7
  })
  const decryptedStr = decrypt.toString(CryptoJS.enc.Utf8)
  return decryptedStr.toString()
}

// 加密方法
function Encrypt(word, key, iv) {
  key = key ? CryptoJS.enc.Utf8.parse(key) : globalLey
  iv = iv ? CryptoJS.enc.Utf8.parse(iv) : globalIv
  // var srcs = CryptoJS.enc.Utf8.parse();
  var encrypted = CryptoJS.AES.encrypt(word, key, {
    iv: iv,
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7
  })
  return encrypted.toString()
}
export default {
  install: function(Vue) {
    Vue.prototype.$Aes = { Decrypt, Encrypt }
  }

}
